const PAYMENT_CONSTANTS = {
  SELECT_YOUR_PAYMENT: 'SELECT YOUR PAYMENT',

  BRAINTREE_PAYMENT_TYPE: {
    KEY_CARD: 'card',
    KEY_CREDIT_CARD: 'CreditCard',
    KEY_PAY_PAL: 'paypal',
    KEY_PAY_PAL_ACCOUNT: 'PayPalAccount',
    KEY_PAY_PAL_CREDIT: 'paypalCredit',
    KEY_GOOGLE_PAY: 'googlePay',
    KEY_ANDROID_PAY_CARD: 'AndroidPayCard',
    KEY_APPLE_PAY: 'applePay',
    KEY_APPLE_PAY_CARD: 'ApplePayCard',
    KEY_VENMO_ACCOUNT: 'VenmoAccount',
  },
  BRAINTREE_EVENT: {
    CHANGE_ACTIVE_VIEW: 'changeActiveView',
    PAYMENT_METHOD_REQUESTABLE: 'paymentMethodRequestable',
    NO_PAYMENT_METHOD_REQUESTABLE: 'noPaymentMethodRequestable',
    PAYMENT_OPTION_SELECTED: 'paymentOptionSelected',
  },
  BRAINTREE_CHECKOUT: 'checkout',
  BRAINTREE_VAULT: 'vault',
  BRAINTREE_FINAL: 'final',

  GLOBAL_PAYMENTS_CONSTANTS: {
    YES: 'YES',
    ENROLLED: 'ENROLLED',
    NOT_ENROLLED: 'NOT_ENROLLED',
    SUCCESS_AUTHENTICATED: 'SUCCESS_AUTHENTICATED',
    SUCCESS_ATTEMPT_MADE: 'SUCCESS_ATTEMPT_MADE',
    CHALLENGE_REQUIRED: 'CHALLENGE_REQUIRED',
    GLOBAL_PAYMENTS_3DS_SCRIPT_ID: 'globalPayment3dsScriptId',
    GLOBAL_PAYMENTS_SCRIPT_ID: 'globalPaymentScriptId',
    GLOBAL_PAYMENTS_SANDBOX: 'sandbox',
    GLOBAL_PAYMENTS_PRODUCTION: 'production',
  },

  GLOBAL_PAYMENT_APPLE_PAY_CONFIGURATION_CONSTANTS: {
    API_VERSION: '2021-03-22',
    ALLOWED_CARD_NETWORKS: ['VISA', 'MASTERCARD', 'AMEX'],
    APPLE_PAY_VERSION_NUMBER: 3,
    BUTTON_STYLE_COLOR: 'white',
    BUTTON_TYPE: 'order',
    MERCHANT_CAPABILITIES: ['supports3DS'],
  },

  GLOBAL_PAYMENT_GOOGLE_PAY_CONFIGURATION_CONSTANTS: {
    API_VERSION: '2021-03-22',
    ALLOWED_CARD_NETWORKS: ['VISA', 'MASTERCARD', 'AMEX', 'DISCOVER'],
    ALLOWED_AUTH_METHODS: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    ALLOWED_GOOGLEPAY_CARD_NETWORKS: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'MIR', 'VISA'],
    BUTTON_STYLE_COLOR: 'white',
    BUTTON_TYPE: 'order',
  },

  GLOBAL_PAYMENTS_ADDRESS_VALIDTAION_PAYLOAD_CONSTANTS: {
    ACCOUNT_NAME: 'transaction_processing',
    CHANNEL: 'CNP',
    CAPTURE_MODE: 'AUTO',
    TYPE: 'SALE',
    ENTRY_MODE: 'ECOM',
    CVV_INDICATOR: 'PRESENT',
  },

  GLOBAL_PAYMENTS_BROWSER_DATA_VALUES: {
    COLOR_DEPTH: 0,
    ACCEPT_HEADER: 'text/html,application/xhtml+xml,application/xml;q=9,image/webp,img/apng,*/*;q=0.8',
  },

  PAYMENT_FAILED_SCREEN_TEXT: {
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    BUTTON_TEXT: 'ButtonText',
  },
  PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES: {
    CARD_NUMBER: 'cardNumber',
    CARD_NUMBER_TITLE:"cardNumberTitle",
    CARD_NUMBER_INPUT_PATTERN: /[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4}/,
    CARD_NUMBER_VALIDATION_REGEX: /[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4}/,
    CVV: 'cardCVV',
    CARD_NUMBER_CVC: 'cvc',
    CVV_INPUT_PATTERN: '^([0-9]{3})$',
    CVV_VALIDATION_REGEX: /^([0-9]{3})$/,
    EXPIRY_DATE: 'cardExpDate',
    CARD_NUMBER_EXPIRY: 'expiryDate',
    CARD_EXPIRY_DATE_INPUT_PATTERN: '^(([0][1-9]|[1][0-2])/[2][0-9][0-9][0-9])$',
    CARD_EXPIRY_DATE_VALIDATION_REGEX: /^(([0][1-9]|[1][0-2])\/[2][0-9][0-9][0-9])$/,
    NAME_ON_CARD: 'cardHolderName',
    NAME_ON_CARD_INPUT_PATTERN: '',
    NAME_ON_CARD_VALIDATION_REGEX: /^[A-Za-z]{2,}(?: [A-Za-z]{1,})?(?<!\s)(?: [A-Za-z]{1,})?(?<!\s)$/,
    DELIVERY_ADDRESS_CHECKBOX: 'deliveryAddressCheckbox',
    CHECKBOX: 'checkbox',
    SAVECARD: 'saveCard',
    CARD_NICK_NAME: 'nickName',
    CARD_NICK_NAME_INPUT_PATTERN: '^([a-zA-Z ]{50})$',
    BILLING_ADDRESS: 'billingAddress',
    BILLING_ADDRESS_TITLE:'billingAddressTitle',
    BILLING_ADDRESS_INPUT_PATTERN: '^([a-zA-Z 0-9]{250})$',
    POSTAL_CODE: 'postalCode',
    POSTAL_CODE_INPUT_PATTERN: '^([a-zA-Z 0-9]{20})$',
    ADDRESS: 'address',
    CREDIT_OR_DEBIT_CARD_TYPE: 'creditOrDebitCard',
  },
  PAYMENT_OPTIONS:{
    OS_WINDOWS:"windows",
    OS_IOS:"ios",
    OS_MAC:"macos",
    OS_ANDROID:"android",
    BROWSER_CHROME:"chrome",
    BROWSER_SAFARI:"safari",
    BROWSER_FIREFOX:"firefox",
    BROWSER_EDGE:"edge",
    UNKNOWN:"unknown",

  },
  VERIFIED: 'VERIFIED',
};

export default PAYMENT_CONSTANTS;
